import * as React from "react";
import { useGetField } from "../query/fields";

export const useMenuExpandRelation = ({
  setAnchorRecord,
  value,
  onClickLink,
  column,
  getRecordById,
  onClickDelete,
  recordId,
}) => {
  const foreignProjectId = column?.options?.foreignProjectId;
  const foreignTableId = column?.options?.foreignTableId;

  const relationship = column?.options?.relationship || "many";

  const [dataRecords, setDataRecord] = React.useState([]);
  const [isLoadingRecords, setIsLoadingRecords] = React.useState(false);

  const { data: dataFields, isLoading: isLoadingFields } = useGetField({
    projectId: foreignProjectId,
    tableId: foreignTableId,
    options: {
      refetchOnWindowFocus: false,
    },
    customName: "getFieldsRelation",
  });

  const getForeignRecord = React.useCallback(async () => {
    setIsLoadingRecords(true);
    const data = await Promise.all(
      value.map(async (item) => {
        return await getRecordById({
          foreignProjectId,
          foreignTableId,
          recordId: item,
        });
      })
    );

    // const data = await
    setDataRecord(data);

    setIsLoadingRecords(false);
  }, [foreignProjectId, foreignTableId, getRecordById, value]);

  React.useEffect(() => {
    if (value.length === 0) {
      onClickLink();
    }
  }, [onClickLink, value.length]);

  React.useEffect(() => {
    getForeignRecord();
  }, [getForeignRecord]);

  const handleClose = () => {
    setAnchorRecord(null);
  };

  const handleDelete = (item) => {
    onClickDelete({
      column,
      item,
      row: {
        _id: recordId,
      },
    });
    setAnchorRecord(null);
  };

  return {
    dataFields,
    dataRecords,
    isLoadingFields,
    isLoadingRecords,
    relationship,
    handleClose,
    handleDelete,
  };
};
