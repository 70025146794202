import uniqBy from "lodash.uniqby";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";

import { axios } from "../../../lib/axios";

const getRecordsPagination = async ({
  pageParam,
  projectId,
  tableId,
  query,
}) => {
  const response = await axios.get(
    `/projects/${projectId}/tables/${tableId}/records?$lookup[_id]=*&$skip=${
      pageParam ? pageParam : 0
    }&$limit=50&${query}`
  );

  const limit = response.headers["x-pagination-limit"];
  const skip = response.headers["x-pagination-skip"];
  const total = Number(response.headers["x-total-count"]);
  const nextSkip = Number(skip) + Number(limit);
  return {
    data: response.data,
    nextSkip: total < nextSkip ? false : nextSkip,
    total,
  };
};

const transformRecords = (dataRecords) => {
  let data = [];
  let total = 0;
  dataRecords.pages?.forEach((item, index) => {
    if (index === 0) {
      total = item.total;
    }
    data = data.concat(item.data);
  });

  const uniqData = uniqBy(data, "_id");

  let newRecord = uniqData.reduce((newArr, curr, i) => {
    curr["no"] = i + 1;
    newArr.push(curr);
    return newArr;
  }, []);

  return [newRecord, total];
};

export const useGetRecordPagination = ({ projectId, tableId, query }) =>
  useInfiniteQuery(
    ["getRecordsPagination", projectId, tableId, query],
    ({ pageParam = 0 }) =>
      getRecordsPagination({ pageParam, projectId, tableId, query }),
    {
      enabled: !!projectId && !!tableId,
      refetchOnWindowFocus: false,
      select: transformRecords,
      keepPreviousData: true,
      getNextPageParam: (lastPage) => lastPage.nextSkip ?? false,
    }
  );

const getRecords = async ({ projectId, tableId, filter }) => {
  try {
    const res = await axios.get(
      `/projects/${projectId}/tables/${tableId}/records?$lookup[_id]=*&${
        filter && filter !== "" ? filter : ""
      }`
    );

    return { data: res?.data, error: false };
  } catch (error) {
    return { data: null, error: true };
  }
};

export const useGetRecords = ({ projectId, tableId, filter }) =>
  useQuery(
    ["getRecords", projectId, tableId, filter],
    () => getRecords({ projectId, tableId, filter }),
    {
      refetchOnWindowFocus: false,
    }
  );

const addRecord = async ({ projectId, tableId, values }) => {
  const result = await axios.post(
    `/projects/${projectId}/tables/${tableId}/records`,
    values
  );
  return result;
};

export const useAddRecord = ({ projectId, tableId, options }) => {
  const queryClient = useQueryClient();

  const mutationOptions = options ?? {
    onMutate: async (newRecord) => {
      await queryClient.cancelQueries(["getRecordsPagination", newRecord.id]);

      const previousRecord = queryClient.getQueryData([
        "getRecordsPagination",
        newRecord.id,
      ]);

      queryClient.setQueryData(
        ["getRecordsPagination", newRecord.id],
        newRecord
      );
      return { previousRecord, newRecord };
    },
    onError: (err, newRecord, context) => {
      queryClient.setQueryData(
        ["getRecordsPagination", context.newRecord.id],
        context.previousTodo
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries("getRecordsPagination");
    },
  };

  return useMutation(
    (values) => addRecord({ projectId, tableId, values }),
    mutationOptions
  );
};

const updateRecord = async ({ projectId, tableId, id, obj }) => {
  const result = await axios.patch(
    `/projects/${projectId}/tables/${tableId}/records/${id}`,
    obj
  );
  return result;
};

export const useUpdateRecord = ({ projectId, tableId }) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, obj }) => updateRecord({ projectId, tableId, id, obj }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getRecordsPagination");
      },
      onError: (err) => {
        queryClient.invalidateQueries("getRecordsPagination");
      },
    }
  );
};

const deleteRecord = async ({ projectId, tableId, ids }) => {
  const listId = ids.join(",");
  const result = await axios.delete(
    `/projects/${projectId}/tables/${tableId}/records?records=${listId}`
  );

  return result;
};

export const useDeleteRecord = ({ projectId, tableId }) => {
  const queryClient = useQueryClient();

  return useMutation((ids) => deleteRecord({ projectId, tableId, ids }), {
    onMutate: async (record) => {
      if (record?.id) {
        await queryClient.cancelQueries(["getRecordsPagination", record.id]);

        const previousRecord = queryClient.getQueryData([
          "getRecordsPagination",
          record.id,
        ]);

        queryClient.setQueryData(["getRecordsPagination", record.id], record);
        return { previousRecord, record };
      }
    },
    onError: (err, newRecord, context) => {
      queryClient.setQueryData(
        ["getRecordsPagination", context.record.id],
        context.previousRecord
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries("getRecordsPagination");
    },
  });
};

const linkRecord = async ({
  projectId,
  tableId,
  recordId,
  foreignTableId,
  linkId,
  token,
  fieldId,
  foreignFieldId,
}) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/projects/${projectId}/${tableId}/${recordId}/${foreignTableId}/${linkId}`,
      {
        method: "LINK",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          localField: fieldId,
          foreignField: foreignFieldId,
        }),
      }
    );
    return res.json();
  } catch (err) {
    console.log(err);
  }
};

export const useLinkRecord = ({ projectId, tableId, token, options }) => {
  const mutationOptions = options ?? {};

  return useMutation(
    ({ linkId, recordId, foreignTableId, fieldId, foreignFieldId }) =>
      linkRecord({
        projectId,
        tableId,
        recordId,
        foreignTableId,
        linkId,
        token,
        fieldId,
        foreignFieldId,
      }),
    mutationOptions
  );
};

const unlinkRecord = async ({
  tableIdRelation,
  recordId,
  linkId,
  localField,
  foreignField,
  projectId,
  tableId,
  token,
}) => {
  await fetch(
    `${process.env.REACT_APP_API_URL}/projects/${projectId}/${tableId}/${recordId}/${tableIdRelation}/${linkId}`,
    {
      method: "UNLINK",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        localField,
        foreignField,
      }),
    }
  );
  return "Success";
};

export const useUnlinkRecord = ({ projectId, tableId, token }) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ tableIdRelation, recordId, linkId, localField, foreignField }) =>
      unlinkRecord({
        tableIdRelation,
        recordId,
        linkId,
        localField,
        foreignField,
        projectId,
        tableId,
        token,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getRecordsPagination");
      },
    }
  );
};
