import Axios from "axios";

export const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
const params = new URL(window.location).searchParams;
const token = params.get("token");

if (token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}
