import qs from "qs";
export default function queryBuilder(value) {
  const { hide, sort, group, filter } = value;
  let data = {};

  if (hide && hide.length) {
    data["$fields"] = hide.map((item) => `-${item}`);
  }

  if (sort) {
    data["$sort"] = sort;
  }

  if (group) {
    data["$group"] = Object.keys(group).map((key) => {
      return {
        [key]: group[key],
      };
    });
  }

  if (filter) {
    filter.forEach((item) => {
      let newValue = item.value;

      if (Array.isArray(newValue)) {
        const arrayValue = [];
        newValue.forEach((v) => {
          arrayValue.push(v.id || v.value);
        });
        newValue = arrayValue;
      }

      if (newValue?.value) {
        newValue = newValue.value;
      }

      if (item.operator === "is") {
        data[item.key] = newValue;
      }

      if (item.operator === "is not") {
        data[item.key] = { $ne: newValue };
      }

      if (item.operator === "contains") {
        data[item.key] = { $contains: newValue };
      }

      if (item.operator === "not contains") {
        data[item.key] = { $notContains: newValue };
      }

      if (item.operator === "=") {
        data[item.key] = newValue;
      }

      if (item.operator === "≠") {
        data[item.key] = { $ne: newValue };
      }

      if (item.operator === ">") {
        data[item.key] = { $gt: newValue };
      }

      if (item.operator === "<") {
        data[item.key] = { $lt: newValue };
      }

      if (item.operator === "≥") {
        data[item.key] = { $gte: newValue };
      }

      if (item.operator === "≤") {
        data[item.key] = { $lte: newValue };
      }

      if (item.operator === "includes") {
        data[item.key] = { $in: newValue };
      }

      if (item.operator === "not includes") {
        data[item.key] = { $nin: newValue };
      }
    });
  }

  const query = qs.stringify(data, { encode: false });
  return query;
}
