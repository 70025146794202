import * as React from "react";
import { useDataTable } from "../../hooks/useDataTable";
import MejikTable from "mejik-table";
import { ToolBarContainer } from "../toolbar/ToolBarContainer";
import { makeStyles } from "@material-ui/core";
import { useToolbar } from "../../hooks/useToolBar";
import { MenuExpandAttachmentContainer } from "../menu/MenuExpandAttachmentContainer";
import { DialogAttachmentContainer } from "../dialog/DialogAttachmentContainer";
import { MenuRelationRecord } from "../menu/MenuRelationRecord";
import MenuExpandRelation from "../menu/MenuExpandRelation";

const useStyles = makeStyles({
  container: {
    backgroundColor: "white",
    "& button": {
      fontWeight: 600,
    },
  },
});

export const DataTable = () => {
  const classes = useStyles();
  const {
    columns,
    expandAttachment,
    fields,
    groupBy,
    isEditable,
    mutateUpdateRecord,
    openModalAttachment,
    openModalRelation,
    rows,
    selectedRows,
    tableId,
    tableName,
    createRecord,
    getRecordById,
    handleClickDelete,
    handleDeleteSelected,
    handleRowsChange,
    handleScroll,
    setExpandAttachment,
    setFrontendQuery,
    setOpenModalAttachment,
    setOpenModalRelation,
    setQuery,
    setSelectedRows,
  } = useDataTable();
  const { toolbarProps } = useToolbar({
    fields,
    setQuery,
    setFrontendQuery,
    handleDeleteSelected,
    selectedRows,
  });

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
      className={classes.container}
    >
      <ToolBarContainer
        fields={fields}
        toolbarProps={toolbarProps}
        setQuery={setQuery}
      />
      <div style={{ flex: 1, overflow: "auto" }}>
        <MejikTable
          columns={Array.isArray(columns) ? columns : []}
          rows={rows}
          isEditable={isEditable}
          groupBy={groupBy}
          onRowsChange={handleRowsChange}
          onScroll={handleScroll}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          onClickAdd={createRecord}
          isEmbed={true}
        />
      </div>
      {Boolean(openModalRelation.anchor) &&
        openModalRelation.variant === "add" && (
          <MenuRelationRecord
            anchorRecord={openModalRelation.anchor}
            setAnchorRecord={() => setOpenModalRelation({})}
            recordId={openModalRelation.recordId}
            currTableId={tableId}
            primaryField={openModalRelation.primaryField}
            value={openModalRelation.value}
            column={openModalRelation.column}
          />
        )}

      {Boolean(openModalRelation.anchor) &&
        openModalRelation.variant === "expand" && (
          <MenuExpandRelation
            anchorRecord={openModalRelation.anchor}
            setAnchorRecord={() => setOpenModalRelation({})}
            recordId={openModalRelation.recordId}
            currTableId={tableId}
            primaryField={openModalRelation.primaryField}
            value={openModalRelation.value}
            column={openModalRelation.column}
            getRecordById={getRecordById}
            onClickLink={() =>
              setOpenModalRelation({ ...openModalRelation, variant: "add" })
            }
            onClickDelete={handleClickDelete}
          />
        )}

      {Boolean(expandAttachment.anchor) && (
        <MenuExpandAttachmentContainer
          expandAttachment={expandAttachment}
          anchorEl={expandAttachment.anchor}
          setExpandAttachment={() => setExpandAttachment({})}
          attachments={expandAttachment.attachments}
          recordId={expandAttachment.recordId}
          tableId={tableId}
          tableName={tableName}
          mutateUpdateRecord={mutateUpdateRecord}
          fieldType={expandAttachment.fieldType}
          fieldName={expandAttachment.fieldName}
          setOpenModalAttachment={() =>
            setOpenModalAttachment(expandAttachment)
          }
        />
      )}
      {Boolean(openModalAttachment.anchor) && (
        <DialogAttachmentContainer
          openModalAttachment={openModalAttachment.anchor}
          setOpenModalAttachment={() => setOpenModalAttachment({})}
          recordId={openModalAttachment.recordId}
          fieldName={openModalAttachment.fieldName}
          mutateUpdateRecord={mutateUpdateRecord}
          attachments={openModalAttachment.attachments}
          tableId={tableId}
          tableName={tableName}
        />
      )}
    </div>
  );
};
