/* eslint-disable react-hooks/exhaustive-deps */
import { Menu } from "@material-ui/core";
import { MenuLinkToRecord } from "mejik-table";
import PropTypes from "prop-types";
import * as React from "react";
import { useMenuExpandRelation } from "../../hooks/useMenuExpandRelation";

const MenuExpandRelation = ({
  anchorRecord,
  setAnchorRecord,
  primaryField,
  value = [],
  getRecordById,
  onClickLink,
  column,
  onClickDelete,
  recordId,
}) => {
  const {
    dataFields,
    dataRecords,
    isLoadingFields,
    isLoadingRecords,
    relationship,
    handleClose,
    handleDelete,
  } = useMenuExpandRelation({
    setAnchorRecord,
    value,
    onClickLink,
    getRecordById,
    column,
    onClickDelete,
    recordId,
  });

  return (
    <Menu
      anchorEl={anchorRecord}
      open={Boolean(anchorRecord)}
      onClose={handleClose}
      variant="menu"
    >
      <MenuLinkToRecord
        onClickLink={relationship === "many" ? onClickLink : null}
        isLoading={isLoadingRecords}
        records={dataRecords}
        fields={dataFields?.data || []}
        primaryField={primaryField || isLoadingFields}
        onClickDelete={handleDelete}
        anchorEl={anchorRecord}
        onClose={handleClose}
      />
    </Menu>
  );
};

MenuExpandRelation.propTypes = {
  anchorRecord: PropTypes.object,
  setAnchorRecord: PropTypes.func,
  primaryField: PropTypes.string,
  value: PropTypes.array,
  getRecordById: PropTypes.func,
  onClickLink: PropTypes.func,
  column: PropTypes.object,
  onClickDelete: PropTypes.func,
  recordId: PropTypes.string,
};

export default MenuExpandRelation;
