import { useMutation, useQuery, useQueryClient } from "react-query";
import { axios } from "../../../lib/axios";

const getFields = async ({ projectId, tableId }) => {
  const result = await axios
    .get(`/projects/${projectId}/tables/${tableId}/fields`)
    .catch((error) => console.log(error));
  return result;
};

const transformFields = (dataFields) => {
  return dataFields.data.map((item) => ({ ...item, field: item.name }));
};

export const useGetField = ({ projectId, tableId, options, customName }) => {
  const queryOptions = options ?? {
    enabled: !!projectId && !!tableId,
    select: transformFields,
    refetchOnWindowFocus: false,
    keepPreviousData: false,
  };
  return useQuery(
    [customName ?? "getFields", projectId, tableId],
    () => getFields({ projectId, tableId }),
    queryOptions
  );
};

const updateFieldType = async ({ projectId, values }) => {
  const result = await axios.post(
    `/projects/${projectId}/fields/${values.fieldId}/updateType`,
    values
  );
  return result;
};

export const useUpdateFieldType = ({ projectId }) => {
  const queryClient = useQueryClient();
  return useMutation((values) => updateFieldType({ projectId, values }), {
    onSuccess: () => {
      queryClient.invalidateQueries("getFields");
    },
    onError: () => {},
  });
};
