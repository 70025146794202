import * as React from "react";

export const useMenuExpandAttachment = ({
  expandAttachment,
  attachments,
  fieldName,
  mutateUpdateRecord,
  recordId,
  tableId,
  setExpandAttachment,
  setOpenModalAttachment,
}) => {
  const [files, setFiles] = React.useState([]);

  React.useEffect(() => {
    if (attachments?.length === 0) {
      setExpandAttachment(null);
      setOpenModalAttachment(true);
    } else if (attachments?.length > 0) {
      setFiles(attachments);
    }
  }, [attachments, setExpandAttachment, setOpenModalAttachment]);

  const handleClose = React.useCallback(() => {
    setExpandAttachment(null);
  }, [setExpandAttachment]);

  const handleUpdate = React.useCallback(
    (data) => {
      const obj = {};
      obj[fieldName] = data;
      mutateUpdateRecord.mutate({ id: recordId, obj, tableId });
      setExpandAttachment({ ...expandAttachment, attachments: data });
    },
    [
      expandAttachment,
      fieldName,
      mutateUpdateRecord,
      recordId,
      setExpandAttachment,
      tableId,
    ]
  );

  const handleDelete = React.useCallback(
    async (data, index) => {
      const filterAttach = attachments.filter((val, i) => i !== index);
      const obj = {};
      obj[fieldName] = filterAttach;
      mutateUpdateRecord.mutate({ id: recordId, obj, tableId });
      setExpandAttachment({ ...expandAttachment, attachments: filterAttach });
    },
    [
      attachments,
      expandAttachment,
      fieldName,
      mutateUpdateRecord,
      recordId,
      setExpandAttachment,
      tableId,
    ]
  );

  return {
    files,
    handleClose,
    handleDelete,
    handleUpdate,
  };
};
