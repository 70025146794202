import * as React from "react";
import { Routes, Route } from "react-router-dom";
import { DataTable } from "../features/table/components/dataTable/DataTable";

export const MainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<DataTable />} />
    </Routes>
  );
};
