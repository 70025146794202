import { MenuLinkToRecord } from "mejik-table";
import PropTypes from "prop-types";
import * as React from "react";
import { useMenuRelationRecord } from "../../hooks/useMenuRelationRecord";

export const MenuRelationRecord = ({
  anchorRecord,
  setAnchorRecord,
  recordId,
  currTableId,
  primaryField,
  value,
  column,
  onClickItem,
}) => {
  const {
    dataFields,
    filterRecordsRelation,
    find,
    isLoadingRecords,
    isLoadingFields,
    mutateAddRecord,
    handleClose,
    handleLink,
    handleSearch,
    setFind,
  } = useMenuRelationRecord({
    setAnchorRecord,
    recordId,
    currTableId,
    value,
    column,
    onClickItem,
  });

  return (
    <>
      <MenuLinkToRecord
        anchorEl={anchorRecord}
        onClose={handleClose}
        find={{
          value: find,
          onChange: (value) => {
            setFind(value);
            handleSearch(value);
          },
        }}
        onClickAdd={() => {
          if (find) {
            let obj = {};
            const primary = dataFields?.data?.find(
              (item) => item.isPrimary
            ).name;

            obj[primary] = find;
            if (primary) {
              mutateAddRecord.mutate(obj);
            }
          } else {
            mutateAddRecord.mutate({});
          }
        }}
        onClickItem={(item) => {
          handleLink(item._id);
        }}
        fields={dataFields?.data || []}
        isLoading={isLoadingRecords || isLoadingFields}
        records={filterRecordsRelation}
        primaryField={primaryField}
        onSubmit={() => {
          if (!filterRecordsRelation?.length && find) {
            let obj = {};
            const primary = dataFields?.data?.find(
              (item) => item.isPrimary
            ).name;

            obj[primary] = find;

            if (primary) {
              mutateAddRecord.mutate(obj);
            }
          } else {
            handleLink(filterRecordsRelation[0]._id);
          }
        }}
      />
    </>
  );
};

MenuRelationRecord.propTypes = {
  anchorRecord: PropTypes.object,
  setAnchorRecord: PropTypes.func,
  recordId: PropTypes.string,
  currTableId: PropTypes.string,
  primaryField: PropTypes.string,
  value: PropTypes.any,
  column: PropTypes.object,
  onClickItem: PropTypes.func,
};
