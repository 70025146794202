import * as React from "react";
import { useSearchParams } from "react-router-dom";
import { useUploadFileMutation } from "../query/files";

export const useDialogAttachment = ({
  recordId,
  fieldName,
  mutateUpdateRecord,
  attachments,
  type,
  onChange,
  tableId,
  fieldType,
  setFieldValue,
  setOpenModalAttachment,
  values,
}) => {
  const [params] = useSearchParams();

  const projectId = params.get("projectId");

  const mutateUploadFile = useUploadFileMutation({ projectId });

  const handleUpload = React.useCallback(
    async (files) => {
      try {
        const newAttachments = [];

        for (let index = 0; index < files.length; index++) {
          const item = files[index];
          if (item.file) {
            let formData = new FormData();

            formData.append("file", item.file);
            const res = await mutateUploadFile.mutateAsync(formData);
            newAttachments.push({
              url: res.data.url,
              fileName: res.data.fileName,
            });
          } else {
            newAttachments.push({
              url: item.url,
              fileName: item.fileName,
            });
          }
        }

        if (type !== "form") {
          if (fieldType === "linkToRecord") {
            setFieldValue(fieldName, [...values[fieldName], ...newAttachments]);
          } else {
            const obj = {};
            obj[fieldName] = [...attachments, ...newAttachments];
            mutateUpdateRecord.mutate({ id: recordId, obj, tableId });
          }
        } else {
          onChange([...attachments, ...newAttachments]);
        }
        setOpenModalAttachment(false);
      } catch (error) {
        console.log(error);
      }
    },
    [
      attachments,
      fieldName,
      fieldType,
      mutateUpdateRecord,
      mutateUploadFile,
      onChange,
      recordId,
      setFieldValue,
      setOpenModalAttachment,
      tableId,
      type,
      values,
    ]
  );

  return { handleUpload };
};
