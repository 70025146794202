import * as React from "react";
import { useQueryClient } from "react-query";
import queryBuilder from "../utils/queryBuilder";

export const useToolbar = ({
  setQuery,
  setFrontendQuery,
  handleDeleteSelected,
  selectedRows,
}) => {
  const queryClient = useQueryClient();
  const [state, setState] = React.useState({ filter: [] });
  const [frontendState, setFrontendState] = React.useState({});

  const setApiQuery = React.useCallback(
    (value) => {
      const query = queryBuilder(value);
      setQuery(query);
      queryClient.invalidateQueries("getRecordsPagination");
    },
    [queryClient, setQuery]
  );

  const setFrontendApiQuery = React.useCallback(
    (value) => {
      const query = queryBuilder(value);
      setFrontendQuery(query);
    },
    [setFrontendQuery]
  );

  const handleChange = React.useCallback(
    (type) => (value) => {
      if (["sort"].includes(type)) {
        const newValue = {
          ...state,
          [type]: value,
        };

        setState(newValue);
        setApiQuery(newValue);
      } else if (["hide", "group"].includes(type)) {
        const newValue = {
          ...frontendState,
          [type]: value,
        };

        setFrontendState(newValue);
        setFrontendApiQuery(newValue);
      } else {
        const newValue = {
          ...state,
          [type]: value,
        };

        setState(newValue);
      }
    },
    [frontendState, setApiQuery, setFrontendApiQuery, state]
  );

  const toolbarProps = {
    hide: {
      value: frontendState.hide,
      onChange: handleChange("hide"),
    },
    filter: {
      value: state.filter,
      onChange: handleChange("filter"),
      onSubmit: (filter) => {
        const newValue = { ...state };
        newValue.filter = filter;
        setApiQuery(newValue);
      },
      onClose: (filter) => {
        const newValue = { ...state };
        newValue.filter = filter.filter((item) => item.value !== "");
        setApiQuery(newValue);
        setState(newValue);
      },
    },
    group: {
      value: frontendState.group,
      onChange: handleChange("group"),
    },
    sort: {
      value: state.sort,
      onChange: handleChange("sort"),
    },

    onClickDelete: handleDeleteSelected,
    disableDelete: !selectedRows?.size > 0,
  };

  return { toolbarProps };
};
