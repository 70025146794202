import * as React from "react";
import uniqBy from "lodash.uniqby";
import { useSearchParams } from "react-router-dom";
import QueryString from "qs";
import { CircularProgress, IconButton } from "@material-ui/core";
import useDebouncedMemo from "../../../hooks/useDebouncedMemo";
import cache from "../utils/cache";
import queryString from "qs";
import { Add as AddIcon } from "@material-ui/icons";
import {
  useAddRecord,
  useDeleteRecord,
  useGetRecordPagination,
  useUnlinkRecord,
  useUpdateRecord,
} from "../query/records";
import { useGetField, useUpdateFieldType } from "../query/fields";
import { axios } from "../../../lib/axios";

const isEditable = true;

export const useDataTable = () => {
  const [params] = useSearchParams();
  const projectId = params.get("projectId");
  const tableId = params.get("tableId");
  const tableName = params.get("table") || "Table";
  const token = params.get("token");

  const [query, setQuery] = React.useState("");
  const [frontendQuery, setFrontendQuery] = React.useState("");
  const [newFields, setNewFields] = React.useState(null);
  const [queryHideFields, setQueryHideFields] = React.useState(null);
  const [selectedRows, setSelectedRows] = React.useState();
  const [expandAttachment, setExpandAttachment] = React.useState({});
  const [openModalAttachment, setOpenModalAttachment] = React.useState({});
  const [openModalRelation, setOpenModalRelation] = React.useState({});

  const { data: dataRecords, ...recordQuery } = useGetRecordPagination({
    projectId,
    tableId,
    query,
  });
  const { data: dataFields } = useGetField({ projectId, tableId });
  const mutateAddRecord = useAddRecord({ projectId, tableId });
  const mutateUpdateRecord = useUpdateRecord({ projectId, tableId });
  const mutateDeleteRecord = useDeleteRecord({ projectId, tableId });
  const mutateUpdateFieldOption = useUpdateFieldType({ projectId });
  const mutateUnlinkRecord = useUnlinkRecord({ projectId, tableId, token });

  const getRecordById = React.useCallback(
    async ({ foreignProjectId, foreignTableId, recordId }) => {
      try {
        let result = cache.get(recordId);

        if (result) {
          return result;
        }

        const response = await axios.get(
          `/projects/${foreignProjectId}/tables/${foreignTableId}/records/${recordId}`
        );

        result = response.data;
        cache.set(recordId, result);
        return result;
      } catch (err) {
        console.log(err);
        return null;
      }
    },
    []
  );

  const handleGetRecord = React.useCallback(
    async (value = [], column) => {
      const foreignTableId = column?.options?.foreignTableId;
      const foreignProjectId = column?.options?.foreignProjectId;

      const result = [];

      for (let i = 0; i < value.length; i++) {
        const item = value[i];

        if (i < 5) {
          const get = await getRecordById({
            foreignProjectId,
            foreignTableId,
            recordId: item,
          });

          if (get) {
            result.push(get);
          }
        }
      }
      return result;
    },
    [getRecordById]
  );

  const handleClickAdd = React.useCallback(
    ({ value, currentTarget, column, row, variant }) => {
      const { options, type } = column;

      if (type === "linkToRecord") {
        const resolvedAnchorEl = currentTarget.getBoundingClientRect();
        const anchorEl = () => ({
          nodeType: 1,
          getBoundingClientRect: () => resolvedAnchorEl,
        });

        setOpenModalRelation({
          variant,
          value,
          column,
          anchor: anchorEl,
          recordId: row?.["id"] || row?.["_id"],
          fieldIdRelation: options.foreignFieldId,
          primaryField: "name",
          fieldId: column.id,
        });
      }

      if (type === "attachment") {
        const resolvedAnchorEl = currentTarget.getBoundingClientRect();
        const anchorEl = () => ({
          nodeType: 1,
          getBoundingClientRect: () => resolvedAnchorEl,
        });

        if (variant === "add") {
          setOpenModalAttachment({
            anchor: anchorEl,
            viewActiveIdRelation: "",
            recordId: row?.["id"] || row?.["_id"],
            fieldName: column.key,
            attachments: row[column.key] || [],
          });
        }
        if (variant === "expand") {
          setExpandAttachment({
            anchor: anchorEl,
            viewActiveIdRelation: "",
            recordId: row?.["id"] || row?.["_id"],
            fieldName: column.key,
            attachments: row[column.key] || [],
            fieldType: type,
          });
        }
      }
    },
    []
  );

  const handleClickDelete = React.useCallback(
    ({ item, row, column }) => {
      const { options, type } = column;

      if (type === "linkToRecord") {
        mutateUnlinkRecord.mutate({
          tableIdRelation: options.foreignTableId,
          recordId: row.id || row._id,
          linkId: item.id || item._id,
          localField: column.id,
          foreignField: options.foreignFieldId,
        });
        return;
      }
    },
    [mutateUnlinkRecord]
  );

  const getFieldsRelation = React.useCallback(
    async (tableIdRelation) => {
      try {
        const result = await axios.get(
          `/projects/${projectId}/tables/${tableIdRelation}/fields`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        return result.data;
      } catch (err) {
        console.log(err);
      }
    },
    [projectId, token]
  );

  const getData = React.useCallback(
    async (type, params) => {
      try {
        if (type === "project") {
          // const result = await axios.get(`${process.env.NEXT_PUBLIC_API_URL}/workspaces/${workspaceId}/projects/`, {
          //   headers: { Authorization: `Bearer ${token}` },
          // });
          // return result.data;
        }

        if (type === "table") {
          const result = await axios.get(
            `/projects/${params?.id || projectId}/tables`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          return result.data;
        }

        if (type === "field") {
          if (params?.tableId) {
            const data = getFieldsRelation(params.tableId);
            return data;
          }

          return newFields;
        }

        return [];
      } catch (error) {
        return [];
      }
    },
    [getFieldsRelation, newFields, projectId, token]
  );

  const getStyles = React.useCallback(
    async (type = "checkBox") => {
      try {
        const result = await axios.get(
          `/projects/${projectId}/fieldTypes/${type}/styles`
        );

        return result.data;
      } catch (error) {
        return [];
      }
    },
    [projectId]
  );

  const [records, totalRecords] = dataRecords || [[], 0];

  const getColumn = React.useCallback(
    (items) => {
      if (!items) {
        return [];
      }

      const result = items.map((item, index) => {
        if (item.type === "linkToRecord") {
          item.loadMany = handleGetRecord;
        }

        return {
          ...item,
          key: item.field,
          name: item.field,
          frozen: item.frozen ? item.frozen : index === 1 ? true : false,
          width: item.width || 180,
          getStyles,
          hideMenu: true,
          onClickAdd: handleClickAdd,
          onClickDelete: handleClickDelete,
          getData,
          summaryFormatter: () => {
            if (index === 0 && recordQuery.isFetchingNextPage) {
              return (
                <CircularProgress
                  size={16}
                  style={{
                    marginLeft: 12,
                    marginTop: 5,
                  }}
                />
              );
            }

            if (index === 1) {
              return (
                <div style={{ fontSize: "0.7rem" }}>{totalRecords} records</div>
              );
            }

            return null;
          },
        };
      });

      return result;
    },
    [
      getData,
      getStyles,
      handleClickAdd,
      handleClickDelete,
      handleGetRecord,
      recordQuery.isFetchingNextPage,
      totalRecords,
    ]
  );

  const getRow = React.useCallback(
    (items) => {
      if (!items) {
        return [];
      }

      if (!newFields) {
        return [];
      }

      const linkToRecordFields = [];

      for (let index = 0; index < newFields.length; index++) {
        const element = newFields[index];

        if (element.type === "linkToRecord") {
          linkToRecordFields.push(element);
        }
      }

      return items.map((item) => {
        for (let index = 0; index < linkToRecordFields.length; index++) {
          const column = linkToRecordFields[index];
          const value = item[column.field] || [];
          const newValue = [];

          for (let index = 0; index < value.length; index++) {
            const id = value[index];

            if (index < 5) {
              try {
                const getDetail = cache.get(id);

                if (getDetail) {
                  newValue.push(getDetail);
                }
              } catch (error) {
                console.log(error);
              }
            }
          }

          item["cache_" + column.field] = newValue;
        }

        return item;
      });
    },
    [newFields]
  );

  const createRecord = React.useCallback(
    (values) => {
      mutateAddRecord.mutate(values);
    },
    [mutateAddRecord]
  );

  const fields = React.useMemo(() => dataFields || [], [dataFields]);

  const newRecords = React.useMemo(() => {
    if (isEditable) {
      const data = [
        ...records,
        {
          _id: "create",
          no: (
            <IconButton
              size="small"
              onClick={() => {
                const primaryField = fields.find((item) => item.isPrimary);

                if (primaryField) {
                  createRecord({ [primaryField.name]: null });
                }
              }}
              style={{ marginLeft: 6 }}
            >
              <AddIcon fontSize="small" />
            </IconButton>
          ),
        },
      ];
      return uniqBy(data, "_id");
    }

    return [...records];
  }, [createRecord, fields, records]);

  const handleRowsChange = React.useCallback(
    async (updatedRows, { indexes, column }) => {
      try {
        const { type } = column;
        const index = indexes[0];
        const values = updatedRows[index];
        const value = values[column.key];
        const id = values._id || values.id;
        const obj = {};

        obj[column.key] = value;

        if (newRecords[index]) {
          newRecords[index][column.key] = value;
        }

        if (values._group) {
          obj[values._group.key] = values._group.value;
        }

        if (type === "singleSelect") {
          obj[column.key] = value.id;

          if (value?.type === "new") {
            const options = column.options.options;
            const typeOptions = column.options;

            options.push({ color: "blue", value: value.value });
            typeOptions.options = options;
            await mutateUpdateFieldOption.mutateAsync({
              type,
              typeOptions,
              default: column.defaultValue,
              fieldId: column.id,
            });
          }
        }

        if (type === "multiSelect") {
          obj[column.key] = value.map((item) => {
            if (item.id) return item.id;

            return item;
          });

          if (value.find((item) => item.type === "new")) {
            const options = column.options.options;
            const typeOptions = column.options;
            const newOption = value.find((item) => item.type === "new");

            options.push({ color: "blue", value: newOption.value });
            typeOptions.options = options;
            await mutateUpdateFieldOption.mutateAsync({
              type,
              typeOptions,
              default: column.defaultValue,
              fieldId: column.id,
            });
          }
        }

        const validateEmail = (obj) => {
          const { email } = obj;
          let regex =
            // eslint-disable-next-line no-useless-escape
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

          if (!regex.test(email)) {
            // enqueueSnackbar("Invalid Email", { variant: "warning" });
          } else {
            if (id.includes("create")) {
              mutateAddRecord.mutate(obj);
            } else {
              mutateUpdateRecord.mutate({ id, obj, tableId });
            }
          }
        };

        if (id.includes("create")) {
          if (type === "email") {
            validateEmail(obj);
          } else {
            mutateAddRecord.mutate(obj);
          }
        } else {
          if (type === "email") {
            validateEmail(obj);
          } else {
            mutateUpdateRecord.mutate({ id, obj, tableId });
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    [
      mutateAddRecord,
      mutateUpdateFieldOption,
      mutateUpdateRecord,
      newRecords,
      tableId,
    ]
  );

  const handleDeleteSelected = React.useCallback(() => {
    const removeId = [];
    selectedRows?.forEach((item) => {
      if (item !== "create") {
        removeId.push(item);
      }
    });
    mutateDeleteRecord.mutate(removeId);
  }, [mutateDeleteRecord, selectedRows]);

  const isAtBottom = React.useCallback((event) => {
    const target = event.target;
    return target.clientHeight + target.scrollTop >= target.scrollHeight;
  }, []);

  const handleScroll = React.useCallback(
    (event) => {
      if (!isAtBottom(event)) return;
      if (recordQuery.hasNextPage) {
        recordQuery.fetchNextPage();
      }
    },
    [isAtBottom, recordQuery]
  );

  React.useEffect(() => {
    if (frontendQuery) {
      let listHideFields = [];
      const parse = queryString.parse(frontendQuery);

      if (parse["$fields"]) {
        listHideFields = parse["$fields"].map((item) => item.replace("-", ""));
      }

      setQueryHideFields(listHideFields);
    } else {
      setQueryHideFields("");
    }
  }, [frontendQuery]);

  const updateNewField = React.useCallback(
    async (sortFields) => {
      const mapField = await Promise.all(
        sortFields.map(async (item) => {
          if (item.type === "linkToRecord") {
            const foreignField = await getFieldsRelation(
              item.options.foreignTableId
            );
            const primaryField = foreignField.find((item) => item.isPrimary);

            return {
              ...item,
              options: { ...item.options, primaryKey: primaryField.name },
            };
          }

          return item;
        })
      );
      setNewFields([
        {
          maxWidth: 60,
          width: 60,
          field: "no",
          type: "selected",
          frozen: true,
        },
        ...mapField,
      ]);
    },
    [getFieldsRelation]
  );

  React.useEffect(() => {
    const sortFields = fields.reduce((prev, curr) => {
      if (!queryHideFields?.includes(curr.field)) {
        prev.push(curr);
      }

      return prev;
    }, []);

    updateNewField(sortFields);
  }, [queryHideFields, fields, updateNewField]);

  const rows = React.useMemo(() => getRow(newRecords), [getRow, newRecords]);

  const columns = useDebouncedMemo(
    () => getColumn(newFields),
    [newFields, recordQuery.isFetchingNextPage, totalRecords],
    100
  );
  const groupBy = [];

  if (frontendQuery) {
    const parse = QueryString.parse(frontendQuery);

    if (Array.isArray(parse["$group"])) {
      parse["$group"].forEach((item) => {
        groupBy.push(Object.keys(item)[0]);
      });
    }
  }

  return {
    columns,
    expandAttachment,
    fields,
    groupBy,
    isEditable,
    mutateUpdateRecord,
    openModalAttachment,
    openModalRelation,
    rows,
    selectedRows,
    tableId,
    tableName,
    createRecord,
    getRecordById,
    handleClickDelete,
    handleDeleteSelected,
    handleRowsChange,
    handleScroll,
    setExpandAttachment,
    setFrontendQuery,
    setOpenModalAttachment,
    setOpenModalRelation,
    setQuery,
    setSelectedRows,
  };
};
