import { DialogAttachment } from "mejik-table";
import PropTypes from "prop-types";
import * as React from "react";
import { useDialogAttachment } from "../../hooks/useDialogAttachment";

export const DialogAttachmentContainer = ({
  openModalAttachment,
  setOpenModalAttachment,
  recordId,
  fieldName,
  mutateUpdateRecord,
  attachments,
  type,
  onChange,
  tableId,
  fieldType,
  setFieldValue,
  values,
}) => {
  const { handleUpload } = useDialogAttachment({
    recordId,
    fieldName,
    mutateUpdateRecord,
    attachments,
    type,
    onChange,
    tableId,
    fieldType,
    setFieldValue,
    setOpenModalAttachment,
    values,
  });

  return (
    <>
      <DialogAttachment
        open={openModalAttachment}
        onClose={() => setOpenModalAttachment(false)}
        onUpload={handleUpload}
      />
    </>
  );
};

DialogAttachmentContainer.propTypes = {
  openModalAttachment: PropTypes.bool,
  setOpenModalAttachment: PropTypes.func,
  recordId: PropTypes.string,
  fieldName: PropTypes.string,
  mutateUpdateRecord: PropTypes.object,
  attachments: PropTypes.array,
  type: PropTypes.string,
  onChange: PropTypes.func,
  tableId: PropTypes.string,
  fieldType: PropTypes.string,
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
};
