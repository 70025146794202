import debounce from "lodash.debounce";
import * as React from "react";
import { useQueryClient } from "react-query";
import { useSearchParams } from "react-router-dom";
import { useGetField } from "../query/fields";
import { useAddRecord, useGetRecords, useLinkRecord } from "../query/records";

export const useMenuRelationRecord = ({
  setAnchorRecord,
  recordId,
  currTableId,
  value,
  column,
  onClickItem,
}) => {
  const [params] = useSearchParams();
  const token = params.get("token");
  const projectId = params.get("projectId");

  const queryClient = useQueryClient();
  const [find, setFind] = React.useState("");
  const [search, setSearch] = React.useState("");
  const foreignProjectId = column?.options?.foreignProjectId;
  const foreignTableId = column?.options?.foreignTableId;
  const foreignFieldId = column?.options?.foreignFieldId;
  const fieldId = column.id;

  const filter = search ? `&name=${search}` : "";

  const { data: dataRecords, isLoading: isLoadingRecords } = useGetRecords({
    projectId: foreignProjectId,
    tableId: foreignTableId,
    filter,
  });

  const { data: dataFields, isLoading: isLoadingFields } = useGetField({
    projectId: foreignProjectId,
    tableId: foreignTableId,
    options: {
      refetchOnWindowFocus: false,
    },
    customName: "getFieldsRelation",
  });

  const handleClose = () => {
    setFind("");
    setAnchorRecord(null);
  };

  const mutateLinkRecord = useLinkRecord({
    projectId,
    tableId: currTableId,
    token,
    options: {
      onSuccess: () => {
        handleClose();
        queryClient.invalidateQueries("getRecordsPagination");
      },
    },
  });

  const handleLink = (id) => {
    if (onClickItem) {
      const item = filterRecordsRelation.find((data) => data._id === id);
      onClickItem(item, column);
    } else {
      mutateLinkRecord.mutate({
        linkId: id,
        recordId,
        foreignTableId,
        fieldId,
        foreignFieldId,
      });
    }
    setAnchorRecord(null);
  };

  const mutateAddRecord = useAddRecord({
    projectId,
    tableId: foreignTableId,
    options: {
      onSuccess: (res) => {
        handleLink(res.data._id);
      },
    },
  });

  const filterRecordsByExistId = dataRecords?.data?.reduce((prev, curr) => {
    const isEqual = value?.find((item) => item === curr._id);

    if (!isEqual) {
      prev.push(curr);
    }

    return prev;
  }, []);

  const filterRecordsRelation = filterRecordsByExistId;

  const handleSearch = debounce((value) => {
    setSearch(value);
  }, 1000);

  return {
    dataFields,
    filterRecordsRelation,
    find,
    isLoadingRecords,
    isLoadingFields,
    mutateAddRecord,
    handleClose,
    handleLink,
    handleSearch,
    setFind,
  };
};
