import { MenuExpandAttachment } from "mejik-table";
import PropTypes from "prop-types";
import * as React from "react";
import { useMenuExpandAttachment } from "../../hooks/useMenuExpandAttachment";

export const MenuExpandAttachmentContainer = ({
  expandAttachment,
  anchorEl,
  setExpandAttachment,
  attachments,
  recordId,
  tableId,
  mutateUpdateRecord,
  fieldName,
  setOpenModalAttachment,
}) => {
  const { files, handleClose, handleDelete, handleUpdate } =
    useMenuExpandAttachment({
      expandAttachment,
      attachments,
      fieldName,
      mutateUpdateRecord,
      recordId,
      tableId,
      setExpandAttachment,
      setOpenModalAttachment,
    });

  return (
    <MenuExpandAttachment
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      onDelete={handleDelete}
      onUpdate={handleUpdate}
      onClickAttachFile={() => setOpenModalAttachment(true)}
      attachments={files}
    />
  );
};

MenuExpandAttachmentContainer.propTypes = {
  expandAttachment: PropTypes.object,
  anchorEl: PropTypes.any,
  setExpandAttachment: PropTypes.func,
  attachments: PropTypes.array,
  recordId: PropTypes.string,
  tableId: PropTypes.string,
  mutateUpdateRecord: PropTypes.object,
  fieldType: PropTypes.string,
  fieldName: PropTypes.string,
  setOpenModalAttachment: PropTypes.func,
};
