import { makeStyles } from "@material-ui/core/styles";
import { ToolBar } from "mejik-table";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  container: {
    backgroundColor: "white",
    "& button": {
      fontWeight: 600,
    },
  },
});

export const ToolBarContainer = ({ fields, toolbarProps }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <ToolBar fields={fields} {...toolbarProps} />
    </div>
  );
};

ToolBarContainer.propTypes = {
  fields: PropTypes.array,
  toolbarProps: PropTypes.object,
  setQuery: PropTypes.func,
};
