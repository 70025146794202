import { axios } from "../../../lib/axios";
import { useMutation } from "react-query";

const uploadFile = async ({ projectId, values }) => {
  const result = await axios.post(`/storage/upload`, values, {
    headers: {
      "content-type": "multipart/form-data",
      "X-Resource-ID": projectId,
    },
  });
  return result;
};

export const useUploadFileMutation = ({ projectId }) =>
  useMutation((values) => uploadFile({ projectId, values }), {
    onError: () => {},
  });
